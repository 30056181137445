<template>
  <section>
    <h3 style="text-align: center; font-weight: bold;">FIQUE EM CASA!!!</h3>
    <hr />
    <div class="row d-flex justify-content-center">
      <div class="col-md-3 bordaDesseCara">
        <img
          width="100%"
          height="300px"
          src="https://ifce.edu.br/aracati/noticias/ifce-lanca-portal-para-auxiliar-comerciantes-durante-a-quarentena/whatsapp-image-2020-03-31-at-16-12-30.jpeg/@@images/908c6209-f0ae-4009-8e33-e08e4cae1d65.jpeg"
        />
        <h6>
          Acesse a plataforma
          <a href="http://www.fiquenolar.ifce.edu.br" target="_blank"
            >FiqueNoLar</a
          >
          para buscar estabelecimentos comerciais de todo o Brasil que realizam
          entregas ou permitem retiradas no local.
        </h6>
      </div>
      <div class="col-md-3 bordaDesseCara">
        <img
          width="100%"
          height="300px"
          :src="require('../assets/questionsCorona.png')"
        />
        <h6>
          Tem dúvidas sobre o Coronavírus?
          <a
            href="http://www.saude.df.gov.br/wp-conteudo/uploads/2020/02/Cartilha-Informacoes-Coronavirus.pdf"
            target="_blank"
            >O Ministério da Saúde te responde!</a
          >
        </h6>
      </div>
      <div class="col-md-3 bordaDesseCara">
        <img
          width="100%"
          height="300px"
          :src="require('../assets/covidnews.png')"
        />
        <h6>
          Deseja buscar notícias ou a Unidade Básica de Saúde mais proxima de
          você? Acesse o
          <a
            href="https://coronavirus-app.saude.gov.br/app/inicio"
            target="_blank"
            >CORONAVÍRUS SUS!</a
          >
        </h6>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style scoped>
h6 {
  text-align: justify;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.bordaDesseCara {
  margin: 10px;
  border: 1px solid #999;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);

  border-radius: 10px;
  padding: 10px;
}
</style>
